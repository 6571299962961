import '../sass/nzf2020.scss';
import './polyfills';
import '@babel/polyfill';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'objectFitPolyfill';
import GA from 'springload-analytics.js';

import mobileMenu from './modules/mobile.menu';
import readMore from './modules/read.more';
import onPageReady from './utils/onPageReady';
import { externaliseLinks } from './utils';

const config = Object.assign({
  GA,
  mobileMenu: '.mobile-menu',
  mobileMenuTrigger: '.mobile-menu-toggle',
  gallery: '[data-fancybox]',
  readMoreTrigger: '.js-read-more-trigger',
  theme: 'nzf_2020',

}, window.APP_CONFIG);

function initialise() {
  window.THEME = config.theme;

  externaliseLinks();
  GA.init();
  mobileMenu.init(config);
  readMore.init(config);
  window.objectFitPolyfill();

  if (document.querySelectorAll('[data-reoako-id]')) {
    import('./modules/reoako.js')
        .then(({ default: reoakoInit }) => {
            reoakoInit();
        });
  }

  if (document.getElementById('venue_index_map') || document.getElementById('venue_map')) {
    import(/* webpackChunkName: `map` */ './modules/googlemap.js')
      .then(({ default: map }) => {
        map.init(config);
      });
  }

  if (document.querySelector('[data-event-listing]')) {
    import(/* webpackChunkName: `event-listing` */ './events/index.js')
      .then(({ default: eventListing }) => {
        eventListing.init();
      }).catch(err => {
        console.log(err);
      });
  } else if (
    document.querySelectorAll('[data-shortlist-toggle]').length
    || document.querySelectorAll('[data-shortlist-event]').length
  ) {
    import(/* webpackChunkName: `shortlist` */ './modules/shortlist')
      .then(({ default: shortlist }) => {
        shortlist.init();
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelectorAll('[data-event-card-video]').length) {
    import(/* webpackChunkName: `photo-gallery` */ './modules/event-card-video.js')
      .then(({ default: eventCardVideo }) => {
        eventCardVideo.init(config);
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelectorAll('[data-fancybox]').length) {
    import(/* webpackChunkName: `photo-gallery` */ './modules/photo.gallery.js')
      .then(({ default: gallery }) => {
        gallery.init(config);
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelector('[data-video]')) {
    import(/* webpackChunkName: `video` */ './modules/video.js')
      .then(({ default: video }) => {
        video.init();
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelector('[data-opens-modal]')) {
    import(/* webpackChunkName: `modal` */ './modules/modal.js')
      .then(({ default: modal }) => {
        modal.init();
      }).catch(err => {
        console.log(err);
      });
  }

  const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
  if (collapsibleEls.length > 0) {
    import(/* webpackChunkName: `collapsible` */ './modules/collapsible.js')
      .then(({ default: collapsibleInit }) => {
        collapsibleInit([...collapsibleEls]);
      });
  }

  if (document.getElementById('schoolfest-booking-form')) {
    import(/* webpackChunkName: `school-fest` */ './modules/school-fest.booking.js')
      .then(({ default: bookingForm }) => {
        bookingForm.init();
      }).catch(err => {
        console.log(err);
      });
  }
}

onPageReady(initialise);
